<template>
  <button :disabled="disabled" class="m-menu__button_wrapper" @click="handleClick">
    {{ text }}
  </button>
</template>

<script>
export default {
  name: 'MenuButton',
  props: {
    text: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
    };
  },
  methods: {
    handleClick() {
      this.$emit('click');
    },
  },
  components: {
  },
};
</script>
